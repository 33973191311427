




























import {Component, Vue} from "nuxt-property-decorator";
import CloseModalButton from "../../components/modals/CloseModalButton.vue";

@Component({
  components: {
    CloseModalButton,
  },
})
export default class BaseModal extends Vue {
}
